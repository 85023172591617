<script setup lang="ts">
import { HeaderTheme } from '@/types';

import DefaultLayout from '@/layouts/default.vue';

/********************
 * COMPOSITIONS     *
 ********************/
const appStore = useAppStore();

function onSetNavigation(value: boolean) {
  appStore.sidebarOpen = value;
}
</script>

<template>
  <default-layout>
    <template #header>
      <CiHeader
        :theme="HeaderTheme.gradient"
        :loading-theme="HeaderTheme.transparent"
        loading-name="getHomeData"
        @set-navigation="onSetNavigation"
      />
    </template>
    <slot />
  </default-layout>
</template>
